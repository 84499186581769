import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressDetails } from 'app/models/address/address-details';
import { MethodResponse, PagedModel } from 'app/models/base-models';
import { IncompleteBillerDetails } from 'app/models/payee/incomplete-biller-details';
import { PayeeDetails } from 'app/models/payee/payee-details';
import { PayeeListFilter } from 'app/models/payee/payee-list-filter';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PayeeService {
    constructor(private http: HttpClient) {}
    payee$ = new BehaviorSubject<PayeeDetails | undefined>(undefined); // single
    incompleteBillerDetail$ = new BehaviorSubject<
        IncompleteBillerDetails | undefined
    >(undefined);

    getPaginatedPayees(
        page: number,
        pageSize: number,
        sortKey: string,
        sortOrder: string,
        filters: PayeeListFilter
    ): Observable<PagedModel> {
        let httpParams = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
            .set('sortKey', sortKey || '')
            .set('sortOrder', sortOrder);
        if (filters.clientId) {
            httpParams = httpParams.set('clientId', filters.clientId);
        }
        if (filters.payeeId) {
            httpParams = httpParams.set('payeeId', filters.payeeId);
        }
        if (filters.payeeName) {
            httpParams = httpParams.set('payeeName', filters.payeeName);
        }
        if (filters.tradingName) {
            httpParams = httpParams.set('tradingName', filters.tradingName);
        }
        if (filters.status && filters.status != 'All') {
            httpParams = httpParams.set('status', filters.status);
        }
        if (filters.abn) {
            httpParams = httpParams.set('abn', filters.abn);
        }
        if (filters.abn) {
            httpParams = httpParams.set('abn', filters.abn);
        }
        if (filters.disbursementTypeId && filters.disbursementTypeId != 0) {
            httpParams = httpParams.set(
                'disbursementTypeId',
                filters.disbursementTypeId
            );
        }
        if (filters.payeeTypeId && filters.payeeTypeId != 0) {
            httpParams = httpParams.set('payeeTypeId', filters.payeeTypeId);
        }

        return this.http
            .get(`${environment.apiUrl}/payees?`, {
                params: httpParams,
            })
            .pipe(
                map((res) => {
                    return new PagedModel(
                        res['firstItemOnPage'],
                        res['lastItemOnPage'],
                        res['totalItemCount'],
                        res['items']
                    );
                })
            );
    }

    getDetailsById(id: string): Observable<PayeeDetails> {
        return this.http
            .get<PayeeDetails>(`${environment.apiUrl}/payees/${id}`)
            .pipe(
                map((res: PayeeDetails) => {
                    this.payee$.next(res);
                    return res;
                })
            );
    }

    getPaginatedIncompleteBillers(
        page: number,
        pageSize: number,
        sortKey: string,
        sortOrder: string
    ): Observable<PagedModel> {
        let httpParams = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
            .set('sortKey', sortKey || '')
            .set('sortOrder', sortOrder);

        return this.http
            .get(`${environment.apiUrl}/payees/incomplete-billers?`, {
                params: httpParams,
            })
            .pipe(
                map((res) => {
                    return new PagedModel(
                        res['firstItemOnPage'],
                        res['lastItemOnPage'],
                        res['totalItemCount'],
                        res['items']
                    );
                })
            );
    }

    getIncompleteBillerDetailsById(
        id: string
    ): Observable<IncompleteBillerDetails> {
        return this.http
            .get<IncompleteBillerDetails>(
                `${environment.apiUrl}/payees/incomplete-billers/${id}`
            )
            .pipe(
                map((res: IncompleteBillerDetails) => {
                    this.incompleteBillerDetail$.next(res);
                    return res;
                })
            );
    }

    updateIncompleteBillerDetails(
        id: number,
        incompleteBillerDetails: IncompleteBillerDetails
    ): Observable<MethodResponse> {

       if(incompleteBillerDetails.abn)
            incompleteBillerDetails.abn = incompleteBillerDetails.abn.replace(/\s/g,'');

       if(incompleteBillerDetails.acn)
            incompleteBillerDetails.acn = incompleteBillerDetails.acn.replace(/\s/g,'');
       
        return this.http.put<MethodResponse>(
            `${environment.apiUrl}/payees/incomplete-billers/${id}`,
            incompleteBillerDetails
        );
    }

    getIncompleteBillersCount(): Observable<string> {
        return this.http
            .get<string>(
                `${environment.apiUrl}/payees/incomplete-billers-count`
            )
            .pipe(map((res) => res));
    }

    saveIncompleteBillerAddress(
        id: string,
        addressDetails: AddressDetails
    ): Observable<MethodResponse> {
        return this.http.post<MethodResponse>(
            `${environment.apiUrl}/payees/incomplete-billers/${id}/address`,
            addressDetails
        );
    }
}
