export class BaseModel {
    isActive: boolean;
    createdStr: string;
    createdByUser: AuditUser;

    modifiedStr: string;
    modifiedByUser: AuditUser;
}

export class PagedModel {
    firstItemOnPage: boolean;
    lastItemOnPage: boolean;
    totalItemCount: number;
    items: BaseModel[];

    constructor(
        isFirstPage: boolean,
        isLastPage: boolean,
        totalItems: number,
        itemList: BaseModel[]
    ) {
        this.firstItemOnPage = isFirstPage;
        this.lastItemOnPage = isLastPage;
        this.totalItemCount = totalItems;
        this.items = itemList;
    }
}

export class Item {
    id: string;
    name: string;
    // extra properties
    code: string;
    isActive: boolean;

    constructor(model) {
        this.id = model.id;
        this.name = model.name;
        this.code = model.code;
        this.isActive = model.isActive;
    }
}
export class MethodResponse {
    isSuccess: boolean;
    isWarning: boolean;
    isError: boolean;
    modelId: string;
    message: string;

    public get header() {
        return this.isSuccess ? "OK" : "ERROR";
    }
}

export class AuditUser {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
}

