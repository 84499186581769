import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from 'app/core/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

  constructor(
    private _service: AuthService,
    private _cookieService: CookieService
  ) { }

  private actions: string[] = ["POST", "PUT", "DELETE"];
  private forbiddenActions: string[] = ["HEAD", "OPTIONS"];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // Get tokens from local storage
    let xsrfCookie = this._service.getAntiForgeryCookie();
    let xsrfToken = this._service.getAntiForgeryToken();

    let permitted = this.findByActionName(request.method, this.actions);
    let forbidden = this.findByActionName(request.method, this.forbiddenActions);;

    if (permitted !== undefined && forbidden === undefined && xsrfToken !== null && xsrfCookie !== null) {
      // Add the cookie
      this._cookieService.delete('XSRF-COOKIE');
      this._cookieService.set('XSRF-COOKIE', xsrfCookie);
      request = request.clone({ setHeaders: { "X-XSRF-TOKEN": xsrfToken }, withCredentials: true });
    } 

    return next.handle(request);
  }

  private findByActionName(name: string, actions: string[]): string | undefined {
    return actions.find(action => action.toLocaleLowerCase() === name.toLocaleLowerCase());
  }
}