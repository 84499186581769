import { Component } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavigationService } from './core/navigation/navigation.service';
import { PayeeService } from './services/payee.service';
import { NavigationMenus } from './helpers/app-constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _payeeService: PayeeService) {

        // add hook to state changes
        this._router.events.subscribe((event: Event) => {
            if (this.isNavigationEvent(event)) {
                const navigations = this._navigationService.getNavigations();
                if (navigations?.default) {
                    this.handleIncompleteBillerMenu(navigations.default);
                }
            }
        });
    }

    private isNavigationEvent(event: Event): boolean {
        return event instanceof NavigationEnd 
            && !this.isExcludedUrl(event.url);
    }

    private isExcludedUrl(url: string): boolean {
        const excludedUrls = ['landing', 'mfa', 'sign-in', 'sign-out'];
        return excludedUrls.some(excluded => url.includes(excluded));
    }

    private handleIncompleteBillerMenu(defaultNavigations: any[]): void {
        const billerMenu = defaultNavigations.find(x => x.id == NavigationMenus.IncompleteBillerList);
        if (billerMenu) {
            this._payeeService.getIncompleteBillersCount().subscribe(itemCount => {
                this.updateMenuBadge(NavigationMenus.IncompleteBillerList, itemCount);
            });
        }
    }

    private updateMenuBadge(navId: string, itemCount: string) {
        if (navId && itemCount) {
            // Update the badge title
            this._navigationService.updateNavigationBadge(navId, itemCount);
        }
    }
}
